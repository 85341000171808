import './Menu.css'
import home_icon from './home-icon.png'
import summary_icon from './summary-icon.png'
import about_icon from './about-icon.png'
import contact_icon from './contact-icon.png'

function Menu() {
    return (
        <>
          <nav className="nav-menu">
            <ul className="nav-ul-menu">
              <li className="nav-ul-li-menu"><img src={home_icon} className="basic-icon" alt="logo" />&nbsp;<a href="/" className="nav-ul-li-a-menu">Home</a></li>
              <li className="nav-ul-li-menu"><img src={summary_icon} className="basic-icon" alt="logo" />&nbsp;<a href="/summary" className="nav-ul-li-a-menu">Lanking</a></li>
              <li className="nav-ul-li-menu"><img src={about_icon} className="basic-icon" alt="logo" />&nbsp;<a href="/about" className="nav-ul-li-a-menu">About</a></li>
              <li className="nav-ul-li-menu"><img src={contact_icon} className="basic-icon" alt="logo" />&nbsp;<a href="/contact" className="nav-ul-li-a-menu">Contact</a></li>
            </ul>
          </nav>
        </>
    );
}

export default Menu;

// iconのrgb(25, 26, 126)
