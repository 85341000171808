import React, {useState, useEffect} from 'react'
import Details_Home from '../../parts/Details/Details_Home';
import * as dayjs from 'dayjs'
import '../../../App.css';
import twitter_logo from './2021_Twitter_logo_blue.png'

const Fetch_Summary = () => {

    // 取得できる値は必ず日本時間になる
    const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
    const YYYY = dayjs(jstNow).format("YYYY"); // 2023
    const MM = dayjs(jstNow).format("MM"); // 01-12
    const D = dayjs(jstNow).format("D"); // 1-31
    const H = dayjs(jstNow).format("H"); // 0-23
    const m = dayjs(jstNow).format("m"); // 0-59

    const jstNow2 = jstNow;
    jstNow2.setMonth(jstNow2.getMonth() - 1);
    const last_YYYY = dayjs(jstNow2).format("YYYY");
    const last_MM = dayjs(jstNow2).format("MM");

    let confirmed_YYYY;
    let confirmed_MM;
    if (parseInt(D) >= 3) {
        // 今月確定
        confirmed_YYYY = YYYY;
        confirmed_MM = MM;
    } else {
        if (parseInt(D) == 1) {
            // 先月確定
            confirmed_YYYY = last_YYYY;
            confirmed_MM = last_MM;
        } else {
            if (parseInt(H) >= 1) {
                // 今月確定
                confirmed_YYYY = YYYY;
                confirmed_MM = MM;       
            } else {
                if (parseInt(m) >= 15) {
                    // 今月確定
                    confirmed_YYYY = YYYY;
                    confirmed_MM = MM;
                } else {
                    // 先月確定
                    confirmed_YYYY = last_YYYY;
                    confirmed_MM = last_MM;
                }
            }
        }
    }
    
    const [posts, setPosts] = useState([])

    useEffect(() => {
        fetch(`https://dev-lambda-summary-data.s3.ap-northeast-1.amazonaws.com/${confirmed_YYYY}/${confirmed_MM}/summary_data.json`, {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            setPosts(data)
        })
    },[])

    return (
        <div className='Summary-main'>
            <div className='flex-container'>
              {
                posts.map(post => 
                      <table className='Summary-table'>
                       <tr>
                        <td><img src={twitter_logo} className="Summary-table-logo" alt="logo" /></td>
                        <td align="left"><span className="span-gray">Lanking：</span><b>{post.lank}</b></td>
                      </tr>
                      <tr>
                        <td colSpan="2"><b>{post.name.substr( 0, 8 )}...</b></td>
                      </tr>
                      <tr>
                        <td colSpan="2"><a href={'https://twitter.com/' + post.username}>@{post.username}</a></td>
                      </tr>
                      <tr>
                        <td rowSpan="3"><img src={post.profile_image_url} className="Summary-table-img"/></td>
                        <td align="left"><span className="span-gray">Followers：</span></td>
                      </tr>
                      <tr>
                        <td align="left">{String(post.followers_count).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}人</td>
                      </tr>
                      <tr>
                        <td align="left"><b>Point：<span className="span-red">{String(post.like_count).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</span>pt</b></td>
                      </tr>
                    </table>
                )
              }
            </div>
        </div>
    )
}

export default Fetch_Summary;