import './Details.css'
import '../../../App.css'
import heart_icon from './heart_icon.png';

function Details_Summary() {
    return (
        <details>
          <summary>集計ルール</summary>
          <div class="details-content">
            <ul>
              <li>集計時刻：毎日&nbsp;00:15:00</li>
              <li>集計対象期間：集計実行の前日の月合計</li>
              <li>集計対象アカウント：2023年5月7日時点で<a href="https://www.shugiin.go.jp/internet/itdb_annai.nsf/html/statics/syu/1giin.htm">衆議院議員名簿一覧</a>及び
                    <a href="https://www.sangiin.go.jp/japanese/joho1/kousei/giin/211/giin.htm">参議院議員名簿一覧</a>
                    に記載されている国会議員の所有するツイッターアカウント</li>
              <li><span className="span-bold">Point算出方法：
                <ol>
                  <li>日次集計の<img src={heart_icon} className="basic-icon" alt="logo" />
                    <span className="span-red span-bold">いいね</span>数を記録する</li>
                  <li>1.の<img src={heart_icon} className="basic-icon" alt="logo" />
                    <span className="span-red span-bold">いいね</span>数を月初から前日まで合算する</li>
                  <li><img src={heart_icon} className="basic-icon" alt="logo" />
                    <span className="span-red span-bold">いいね</span>数の合算値の単位をPointとして表示する</li>
                </ol>
                </span>
              </li>
              <li>表示対象：集計後<b>全アカウント</b></li>
              <li>表示順序：集計時点で獲得している<span className="span-bold">Point</span>の多い順
              </li>
            </ul>
          </div>
        </details>
    );
}

export default Details_Summary;