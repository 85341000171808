import { Timeline } from 'react-twitter-widgets';

<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
function About() {

    return (
        <div className="App-main-left">
          <h3>ページ概要</h3>
            <p>　当サイトは日本国の国会議員713名（衆議院議員：465名／参議院議員：248名）のTwitterの利用実績をまとめたサイトです。</p>
          <h3>サイト開設に至った経緯</h3>
            <h4>Twitterは現代社会における基本的インフラです</h4>
            <p>
            　今やTwitterは単なる米国のイチ企業ではなく、<b>オープンな情報交換のインフラとして着実に日本に根付いています。</b>
            平成のIT長者であり現在はロケットエンジンの開発に関わる堀江貴文氏や、２ちゃんねる創設者で若者のオピニオンリーダであるひろゆき氏など数多くの有識者がテレビや新聞といったオールドメディアを介することなくTwitterを通じて利用者と情報交換を行っています。<br/>
            　ここで交換される情報は新鮮ゆえに誤りも多いです。常に多面的に情報を収集し捌くことが情報の受け手に求められます。
            また、Aという主張には瞬時に肯定A'と否定Bの反応が返ってくるため、情報の発信元も発信した情報の評価をリアルタイムで受けることになります。<br />
            　SNS空間においてはこうした現象が日々繰り返されているため、利用者同士が日々情報リテラシーを高め合っていきます。私はこれを国会議員にも求めていきたいと考えています。<br />
            　Twitterアカウントを所有する議員は与野党問わずほとんどの政党に見られる一方、日常的に情報発信を続けているアカウントとなるだいぶ少なくなります。
            Twitterは国民と繋がることができる数少ない手段でもありますから、是非とも各議員のTwitter利用回数が増えてほしいと願っています。
            </p>
            <h4>私の目的はITツールの活用を促すこと</h4>
            <p>
            　私の目的は国会議員のITツールの活用を促進することです。
            Twitterはオープンな情報交換の手段として国会議員に最も活用してほしいツールですが、これ以外にもチャットアプリやビデオ会議アプリも公務の中に積極的に取り入れていってほしいと思っています。
            ペーパレスもリモートワークもです。民間企業が当たり前に進めているDXを国家の最高機関である国会で進めなくて何が先進国なのでしょう。<br />
            　弊社はTwitterアカウントを持たない議員に対して批判的な立場ではありますが、決して彼らに対してネガティブキャンペーンを張って潰したいと考えているわけではありません。
            国家の舵取りをする人たちは新しいものを積極的に取り入れる姿勢であってほしい。そのためにも国会議員アカウントの普及活動に取り組んでいきます。
            </p>
          <h3>About main technologies used on this website</h3>
            <ul>
              <li><span className="span-gray">React／Python／AWS Amplify／AWS Route53／AWS Lambda／AWS EventBridge／AWS S3／AWS SNS</span></li>
              <li><span className="span-gray"></span></li>
              <li><span className="span-gray"><a href="https://developer.twitter.com/en/docs/twitter-api">Twitter API</a></span></li>
              <li><span className="span-gray"><a href="https://icooon-mono.com/">ICOOON MONO</a></span></li>
            </ul>
          <Timeline
              dataSource={{
              sourceType: 'profile',
              screenName: 'kokkawitter' // アカウント名
              }}
              options={{
                height: '800'
              }}
          />
        </div>
    );
}

export default About;