import '../../../App.css';
import Fetch_Summary from './Fetch_Summary';
import MediaQuery from "react-responsive";
import Details_Summary from '../../parts/Details/Details_Summary';
import * as dayjs from 'dayjs'

function Summary() {

    // 取得できる値は必ず日本時間になる
    const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
    const YYYY = dayjs(jstNow).format("YYYY"); // 2023
    const MM = dayjs(jstNow).format("MM"); // 01-12
    const D = dayjs(jstNow).format("D"); // 1-31
    const H = dayjs(jstNow).format("H"); // 0-23
    const m = dayjs(jstNow).format("m"); // 0-59

    const jstNow2 = jstNow;
    jstNow2.setMonth(jstNow2.getMonth() - 1);
    const last_YYYY = dayjs(jstNow2).format("YYYY");
    const last_MM = dayjs(jstNow2).format("MM");

    let confirmed_YYYY;
    let confirmed_MM;
    if (parseInt(D) >= 3) {
        // 今月確定
        confirmed_YYYY = YYYY;
        confirmed_MM = MM;
    } else {
        if (parseInt(D) == 1) {
            // 先月確定
            confirmed_YYYY = last_YYYY;
            confirmed_MM = last_MM;
        } else {
            if (parseInt(H) >= 1) {
                // 今月確定
                confirmed_YYYY = YYYY;
                confirmed_MM = MM;       
            } else {
                if (parseInt(m) >= 15) {
                    // 今月確定
                    confirmed_YYYY = YYYY;
                    confirmed_MM = MM;
                } else {
                    // 先月確定
                    confirmed_YYYY = last_YYYY;
                    confirmed_MM = last_MM;
                }
            }
        }
    }

  return (
    <div className="App">
      <div className="App-main">
        <p>
          <span className="span-orange span-bold">日次集計結果の月間サマリー</span><br/>
          <span className="span-gray">集計対象期間：</span><span className="span-bold">{confirmed_YYYY}年{confirmed_MM}月</span>
        </p>
        <Details_Summary />
        <MediaQuery query="(max-width: 550px)">
          <div className="App-main-sp">
            <Fetch_Summary />
          </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 550px)">
          <div className="App-main-pc">
            <Fetch_Summary />
          </div>
        </MediaQuery>
      </div>
    </div>
  );
}

export default Summary;