import './App.css';
import Menu from './component/parts/Menu/Menu';
import Sns from './component/parts/Sns/Sns';
import Home from './component/pases/Home/Home';
import Summary from './component/pases/Summary/Summary';
import About from './component/pases/About/About';
import Contact from './component/pases/Contact/Contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <header className="App-header">
        <p className="header-p"><a className="header-p-a" href="/">Kokkawitter</a></p>
      </header>
      <Sns />
      <Menu />
      <BrowserRouter>
        <Routes>
          <Route path={`/`} element={<Home />} />
        </Routes>
        <Routes>
          <Route path={`/summary`} element={<Summary />} />
        </Routes>
        <Routes>
          <Route path={`/about`} element={<About />} />
        </Routes>
        <Routes>
          <Route path={`/contact`} element={<Contact />} />
        </Routes>
      </BrowserRouter>
      <Menu />
      <footer className="App-footer">
        <p>© 2023 Sakuranokuni.com. All Rights Reserved.</p>
      </footer>
    </>
  );
}

export default App;
