import '../../../App.css';
import twitter_icon from '../../parts/Sns/twitter-icon.png'
import youtube_icon from '../../parts/Sns/youtube-icon.png'

function Contact() {
  return (
    <div className="App">
      <div className="App-main">
        <table className="contact-table">
          <tr>
            <th>サイト名</th>
            <td>Kokkawitter（国会ッター）</td>
          </tr>
          <tr>
            <th>サイト開設</th>
            <td>2023年3月</td>
          </tr>
          <tr>
            <th>運営会社</th>
            <td><a href="https://sakuranokuni.com/">合同会社桜の国.com</a></td>
          </tr>
          <tr>
            <th>Twitterアカウント</th>
            <td><img src={twitter_icon} className="basic-icon" alt="logo" />&nbsp;<a href="https://twitter.com/Kokkawitter">@kokkawitter</a></td>
          </tr>
          <tr>
            <th>Youtubeチャンネル</th>
            <td><img src={youtube_icon} className="basic-icon" alt="logo" />&nbsp;<a href="https://www.youtube.com/@kokkawitter">Kokkawitter</a></td>
          </tr>
          <tr>
            <th>メール</th>
            <td>kokkawitter@sakuranokuni.com<br/>
              国会ッター運営　担当者宛
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Contact;