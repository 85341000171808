import './Sns.css'
import twitter_icon from './twitter-icon.png'
import youtube_icon from './youtube-icon.png'
import tiktok_icon from './tiktok-icon.png'

function Sns() {
    return (
        <>
          <nav className="nav-sns">
            <ul className="nav-ul-sns">
              <li className="nav-ul-li-sns"><img src={twitter_icon} className="basic-icon" alt="logo" />&nbsp;<a href="https://twitter.com/kokkawitter" className="nav-ul-li-a-sns">Twitter</a></li>
              <li className="nav-ul-li-sns"><img src={youtube_icon} className="basic-icon" alt="logo" />&nbsp;<a href="https://www.youtube.com/@kokkawitter" className="nav-ul-li-a-sns">YouTube</a></li>
              <li className="nav-ul-li-sns"><img src={tiktok_icon} className="basic-icon" alt="logo" />&nbsp;<a  tabindex="-1" className="nav-ul-li-a-sns">TikTok</a></li>
            </ul>
          </nav>
        </>
    );
}

export default Sns;