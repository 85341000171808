import React, {useState, useEffect} from 'react'
import { TwitterTweetEmbed } from 'react-twitter-embed';
import * as dayjs from 'dayjs'
import '../../../App.css';

const Fetch_Home_Pc = () => {

    // 取得できる値は必ず日本時間になる
    const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));    
    jstNow.setDate(jstNow.getDate() - 1);
    const YYYY = dayjs(jstNow).format("YYYY");
    const MM = dayjs(jstNow).format("MM");
    const DD = dayjs(jstNow).format("DD");

    const [posts, setPosts] = useState([])

    useEffect(() => {
        fetch(`https://dev-lambda-get-tweet-seji-data.s3.ap-northeast-1.amazonaws.com/${YYYY}/${MM}/${DD}/day_data.json`, {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            var data_only_50 = []
            for(var i = 0;i < 50; i++) {
                data_only_50.push(data[i])
            }
            setPosts(data_only_50)
        })
    },[])

    return (
        <div className='flex-container'>
            {
                posts.map(post => 
                    <>
                      <TwitterTweetEmbed tweetId={post.tweet_id} />
                    </>
                )
            }
        </div>
    )
}

export default Fetch_Home_Pc;