import './Details.css'
import '../../../App.css'
import heart_icon from './heart_icon.png';

function Details_Home() {
    return (
        <details>
          <summary>集計ルール</summary>
          <div class="details-content">
            <ul>
              <li>集計時刻：毎日&nbsp;00:00:00</li>
              <li>集計対象期間：集計実行の前日&nbsp;0:00:00～23:59:59</li>
              <li>集計対象アカウント：2023年5月7日時点で<a href="https://www.shugiin.go.jp/internet/itdb_annai.nsf/html/statics/syu/1giin.htm">衆議院議員名簿一覧</a>及び
                    <a href="https://www.sangiin.go.jp/japanese/joho1/kousei/giin/211/giin.htm">参議院議員名簿一覧</a>
                    に記載されている国会議員の所有するツイッターアカウント</li>
              <li><span className="span-bold">抽出ツイート：アカウントが前日に投稿したツイートのうち、
                最も<img src={heart_icon} className="basic-icon" alt="logo" />
                <span className="span-red span-bold">いいね</span>が多いツイート1件</span></li>
              <li>表示対象：集計後<b>上位50アカウント</b></li>
              <li>表示順序：集計時点で獲得している<img src={heart_icon} className="basic-icon" alt="logo" />
                <span className="span-red span-bold">いいね</span>の多い順<br />
                ※集計時刻00:00:00以降に追加された<img src={heart_icon} className="basic-icon" alt="logo" />
              <span className="span-red span-bold">いいね</span>は表示順序に反映されない
              </li>
            </ul>
          </div>
        </details>
    );
}

export default Details_Home;