import '../../../App.css';
import Details_Home from '../../parts/Details/Details_Home';
import Fetch_Home_Pc from './Fetch_Home_Pc';
import Fetch_Home_Sp from './Fetch_Home_Sp';
import MediaQuery from "react-responsive";
import * as dayjs from 'dayjs'

function Home() {
  // 取得できる値は必ず日本時間になる
  const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));    
  jstNow.setDate(jstNow.getDate() - 1);
  const YYYY = dayjs(jstNow).format("YYYY");
  const MM = dayjs(jstNow).format("MM");
  const DD = dayjs(jstNow).format("DD");

  return (
      <div className="App-main">
        <p >
          <span className="span-orange span-bold">国会議員のいいね獲得数の多いツイートを毎日自動集計</span><br/>
          <span className="span-gray">集計対象期間：</span><span className="span-bold">{YYYY}年{MM}月{DD}日&nbsp;</span><span className="span-gray">00:00:00～23:59:59</span>
        </p>
        <Details_Home />
        <MediaQuery query="(max-width: 550px)">
          <div className="App-main-sp">
            <Fetch_Home_Sp />
          </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 550px)">
          <div className="App-main-pc">
            <Fetch_Home_Pc />
          </div>
        </MediaQuery>
      </div>
  );
}

export default Home;
